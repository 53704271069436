function About() {
  return (
    <div
      className="container d-flex flex-column align-items-center justify-content-center scoll-appear-animation fadeInAnimation"
      id="about"
    >
      <h1 className="text">About Me:</h1>
      <p className="text">As a software engineer with a passion for internet of things and machine learning, I have been honing my skills in Python development. Through continuous learning and practice, I have been gaining a deeper understanding of software design and development. In addition to my technical abilities, I possess strong communication and teamwork skills, enabling me to effectively collaborate with my colleagues to achieve shared goals.
</p>
    </div>
  );
}

export default About;
